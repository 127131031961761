import { sendPasswordRecoveryEmail } from '../../firebase';
import { Helmet } from "react-helmet";
import { useState } from "react";

import logoGif from "../../assets/images/logoGif.gif";
import LoginIcon1 from "../../assets/images/LoginIcon1.png";
import LoginIcon2 from "../../assets/images/LoginIcon2.png";

const PasswordReset = () => {
    const [email, setEmail] = useState("");

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            sendPasswordRecoveryEmail(email);
        }
    }

    return (
        <>
            <Helmet>
                <title>
                    MyInfluency | Forgot Password
                </title>
            </Helmet>
            <div className="grid grid-cols-1 justify-between items-center h-screen">
                <img src={LoginIcon1} alt="Login Image1" className="w-1/5 justify-self-start" />
                <img src={LoginIcon2} alt="Login Image2" className="w-1/5 justify-self-end" />
                <div className="flex flex-col p-20 justify-self-center bg-white rounded-xl md:shadow-2xl absolute">
                    <img src={logoGif} alt="My Influency" className="object-scale-down w-64 mx-auto mb-8" />
                    <div className="flex flex-col ">
                        <label className="text-left px-4 py-2 font-poppins text-black" htmlFor="email">Please enter your account's email:</label>
                        <input className="p-2 mb-4 border-b-2 border-mi-green" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="your_email@gmail.com" required="required" onKeyPress={handleKeypress}></input>
                        <button className="m-2 mt-8 border-0 rounded-lg p-4 cursor-pointer text-white font-poppins bg-mi-green transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-200" onClick={() => { sendPasswordRecoveryEmail(email) }}>Send Recovery Email</button>
                    </div>
                    <div className="flex flex-row gap-2 rounded-xl w-fit p-4 mt-8">
                        <h2 className="text-center text-base text-gray-600 font-poppins">Already have an account?</h2>
                        <a href="/signin" className="text-center text-mi-green underline">Log in</a>
                    </div>
                    <div className="flex flex-row gap-2 rounded-xl w-fit p-4">
                        <h2 className="text-center text-base text-gray-600 font-poppins">Don't have an account?</h2>
                        <a href="/create_account" className="text-center text-mi-green underline">Join the Community!</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordReset;