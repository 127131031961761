import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../firebase";
import { isoToDate } from "../../dateUtils";

import Swal from "sweetalert2";
import Cookies from "js-cookie";
import ChatIcon from '@mui/icons-material/Chat';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import InfluencerProfileCard from "./InfluencerProfileCard";
import BusinessProfileCard from "./BusinessProfileCard";
import Spinner from "../Spinner";

import InfluencerTimelineCard from "./InfluencerTimelineCard";
import BusinessTimelineCard from "./BusinessTimelineCard";
import InfluencerContractBody from "./InfluencerContractBody";
import BusinessContractBody from "./BusinessContractBody";
import TWButton from "../TWButton";
import ClaimsModal from "./ClaimsModal";
import CompensationModal from "./compensation/CompensationModal";
import VisitDetailModal from "./visit_date/VisitDetailModal";


const DetailsPage = () => {
    const { contractID } = useParams();

    const [data, setData] = useState(null);
    const [openClaims, setOpenClaims] = useState(false);
    const [openVisitDetail, setOpenVisitDetail] = useState(false);
    const [openCompensation, setOpenCompensation] = useState(false);

    // edit proposal data
    const [currency, setCurrency] = useState();
    const [price, setPrice] = useState();
    const [prodName, setProdName] = useState();
    const [prodDes, setProdDes] = useState();
    const [addressID, setAddressID] = useState();
    const [addressLoc, setAddressLoc] = useState();
    const [step, setStep] = useState(null);
    const navigate = useNavigate();

    const csrf_token = Cookies.get("csrftoken");

    const currencyPrice = (price) => {
        const regex = /^(\D+)?([\d,]+(?:\.\d{0,2})?)$/;
        const match = price.match(regex);

        const currencySymbol = match[1] ? match[1].trim() : '';
        const amount = match[2] ? parseFloat(match[2].replace(/,/g, '')) : 0;
        setPrice(amount)
        setCurrency(currencySymbol)
    }

    // function to get contract data on page load
    useEffect(() => {
        const getContract = async () => {
            const token = await auth.currentUser.getIdToken();
            const url = `${process.env.REACT_APP_DJANGO_API}/get_contract_details/${contractID}`;
            const requestMetaData = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrf_token,
                    "Authorization": `Bearer ${token}`,
                },
            };
            try {
                await fetch(url, requestMetaData)
                    .then(res => res.json())
                    .then((result) => {
                        if (!result || !result.success) {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Something went wrong!",
                                confirmButtonColor: "#7BBA83",
                                footer: "Please try again later.",
                            });
                        } else {
                            // generate data
                            setData(result.data);
                            setStep(result.data.step);
                            currencyPrice(result.data.price_details.influencer_price);
                            setProdName(result.data.campaign.product_name);
                            setProdDes(result.data.campaign.product_description);
                            setAddressID(result.data.address.id);
                            setAddressLoc(result.data.address);

                        }
                    });
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    confirmButtonColor: "#7BBA83",
                    footer: "Please try again later.",
                });
            }
        }
        getContract();
    }, []);

    // function for backend call to confirm pickup
    const confirmPickup = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/confirm_contract_visit`;
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ contractId: contractID }),
        };
        try {
            fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "You've confirmed the pickup!",
                            confirmButtonColor: "#7BBA83",
                        }).then(() => window.location.reload());
                    };
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }
    // function for backend call to edit proposal
    const EditProposal = async () => {
        const token = await auth.currentUser.getIdToken();
        const url = `${process.env.REACT_APP_DJANGO_API}/edit_proposal`;
        const requestMetaData = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrf_token,
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(
                {
                    id: contractID,
                    price: price,
                    prodName: prodName,
                    prodDescription: prodDes,
                    address: addressID,
                }),
        };
        try {
            fetch(url, requestMetaData)
                .then(res => res.json())
                .then((result) => {
                    if (!result || !result.success) {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            confirmButtonColor: "#7BBA83",
                            footer: "Please try again later.",
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Done!",
                            text: "You've Edited the Proposal!",
                            confirmButtonColor: "#7BBA83",
                        }).then(() => window.location.reload());
                    };
                });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                confirmButtonColor: "#7BBA83",
                footer: "Please try again later.",
            });
        }
    }
    return (
        <>
            <div className="flex flex-col w-full h-full p-2 md:px-8 py-2">
                <div className="flex-auto">
                    <div className="flex flex-col w-full h-full pt-4 gap-4">
                        {/* MAIN CONTENT */}
                        {/* TIMELINE */}
                        {(data)
                            ? <div className="flex flex-col w-full md:w-3/4 md:mx-auto gap-4">
                                {/* PAGE TTILE */}
                                {data &&
                                    <p className="text-[2rem] font-poppins font-extrabold">
                                        {data.campaign.title} — {data.partner.company_name} — {isoToDate(data.offer_date)}
                                    </p>
                                }
                                {(data.partner.is_influencer === true)
                                    ? <BusinessTimelineCard data={data} step={step} confirmPickup={confirmPickup} />
                                    : <InfluencerTimelineCard data={data} step={step} confirmPickup={confirmPickup} />
                                }


                                {data.partner.is_influencer === true
                                    ? <BusinessContractBody data={data} setOpenCompensation={setOpenCompensation} setOpenVisitDetail={setOpenVisitDetail} edited={{ "addressID": addressID, "addressLoc": addressLoc, "prodName": prodName, "prodDes": prodDes, "price": price, "currency": currency }} />
                                    : <InfluencerContractBody data={data} />
                                }

                                {/* Partner Info */}
                                <h2>Partner Info</h2>
                                <div className="w-full mx-auto p-4 rounded-3xl border shadow-lg drop-shadow-lg bg-white">
                                    {(data.partner.is_influencer === true)
                                        ? <InfluencerProfileCard data={data.partner} />
                                        : <BusinessProfileCard data={data.partner} />
                                    }
                                </div>
                                <div className="w-full flex flex-row justify-end items-center gap-2 md:gap-4 mb-4">
                                    <div>
                                        <TWButton sm onClick={() => navigate(`/chat/${data.contract_id}`)}>
                                            <div className="flex flex-row items-center gap-2">
                                                <ChatIcon className="text-white" />
                                                Send Message
                                            </div>
                                        </TWButton>
                                    </div>
                                    {(data.status === "Current" || data.status === "Fulfilled") && (
                                        <div>
                                            <TWButton sm delete onClick={() => setOpenClaims(!openClaims)}>
                                                <div className="flex flex-row items-center gap-2">
                                                    <ErrorOutlineIcon className="text-white" />
                                                    File a Claim
                                                </div>
                                            </TWButton>
                                        </div>
                                    )}
                                    {(data.partner.is_influencer === true && data.status === "Pending") && (
                                    <div>
                                        <TWButton sm green onClick={() => EditProposal()} disabled={(data.status !== "Pending") && (data.status !== "Ready For Pay")}>
                                            <div className="flex flex-row items-center gap-2">
                                                <ErrorOutlineIcon className="text-white" />
                                                Update
                                            </div>
                                        </TWButton>
                                    </div>
                                    )}
                                </div>

                                {/* Claims Modal */}
                                {openClaims && <ClaimsModal setShowModal={setOpenClaims} data={{ contract_id: data.contract_id, partner: data.partner }} />}
                                {/* Visit Detail Modal */}
                                {openVisitDetail && <VisitDetailModal setShowModal={setOpenVisitDetail} contractID={contractID} setProdDes={setProdDes} setProdName={setProdName} setAddressLoc={setAddressLoc} setAddressID={setAddressID} data={{ "addressID": addressID, "addressLoc": addressLoc, "prodName": prodName, "prodDes": prodDes }} />}
                                {/*Compensation Modal */}
                                {openCompensation && <CompensationModal setShowModal={setOpenCompensation} setPrice={setPrice} price={price} currency={currency} />}

                            </div>
                            : <div className="flex flex-col items-center justify-center h-screen">
                                <Spinner />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
export default DetailsPage;
