import { Checkbox } from "@mui/material";
import { useState } from "react";
import TWButton from "../TWButton";

const UserSignup = (props) => {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [accessKey, setAccessKey] = useState(null);
    const [subscribedMailing, setSubscribedMailing] = useState(false);
    const [tosAccept, setTosAccept] = useState(false);

    const nextStep = () => {
        const data = {
            firstName: firstName,
            lastName: lastName,
            accessKey: accessKey,
            subscribedMailing: subscribedMailing,
            tosAccept: tosAccept,
        };

        props.nextStep(data);
    }

    return (
        <>
            <h3 className="font-bold text-2xl text-gray-600 mb-10">Join Our Community!</h3>

            <h4 className="w-full px-10 font-semibold text-gray-500">First Name</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" placeholder="e.g. John" onChange={e => setFirstName(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Last Name</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" placeholder="e.g. Doe" onChange={e => setLastName(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <h4 className="w-full px-10 font-semibold text-gray-500">Access Key (If You Have One)</h4>
            <div className="w-full flex px-10 mb-10">
                <input type="text" placeholder="e.g. abc123" onChange={e => setAccessKey(e.target.value)} required className="flex-auto rounded-xl p-1 bg-gray-200 outline-gray-400 text-gray-700"></input>
            </div>

            <div className="w-full px-10 flex flex-row items-center mb-4 md:mb-2">
                <Checkbox onChange={() => setSubscribedMailing(!subscribedMailing)} className="text-gray-400" />
                <p className="text-gray-600">Subscribe to My Influency's mailing list to get the latest news and updates!</p>
            </div>

            <div className="w-full px-10 flex flex-row items-center">
                <Checkbox onChange={() => setTosAccept(!tosAccept)} className="text-gray-400" />
                <p className="text-gray-600">I have read and understand the <a target="_blank" rel="noopener noreferrer" href={'https://www.myinfluency.com/terms-of-service'} className="inline text-mi-blue underline">Terms of Service</a> and <a target="_blank" rel="noopener noreferrer" href={'https://www.myinfluency.com/privacy-policy'} className="inline text-mi-blue underline">Privacy Policy</a> and agree to them by creating an account</p>
            </div>

            <div className="flex-auto w-full px-10 flex flex-row  justify-center md:justify-end items-center">
                <div className="flex-auto flex flex-row justify-start">
                    <div className="w-full md:w-[40%]">
                        <TWButton onClick={() => props.setUserType(null)}>Back</TWButton>
                    </div>
                </div>
                <div className="flex w-full md:w-[15%] justify-end items-end">
                    <TWButton disabled={!firstName || !lastName || !tosAccept} onClick={nextStep}>Continue</TWButton>
                </div>
            </div>
        </>
    );
}
export default UserSignup;